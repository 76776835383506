<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <form class="login" @submit.prevent="login">
                <div class="p-8">
                  <div class="vx-card__title mb-8">
                    <h4 class="mb-4">Ingreso</h4>
                    <p>Bienvenido, por favor ingrese a su cuenta.</p>
                  </div>
                  <vs-input
                    name="email"
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="Correo electrónco"
                    v-model="email"
                    class="w-full no-icon-border"
                    required/>

                  <vs-input
                    type="password"
                    name="password"
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Contraseña"
                    v-model="password"
                    class="w-full mt-6 no-icon-border"
                    required/>

                  <br>
                  <vs-button class="float-right">Ingresar</vs-button>
                  <br><br>
                </div>
              </form>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

  import ServicesAPI from "@/services/api-services";

  export default {
    data() {
      return {
        email: '',
        password: '',
        checkbox_remember_me: true
      }
    },
    mounted() {
      if (localStorage.getItem("session_id")) {
        this.$router.push("/");
      }
    },
    methods: {
      login() {

        ServicesAPI.methods.apiPOST({
          url: 'api/session/admin',
          params: {
            email: this.email,
            password: this.password
          }
        }, res => {

          if (res.code == 200) {
            localStorage.setItem("session_id", res.session);
            this.$router.push('/');
          } else {
            this.$vs.notify({
              title: '¡Problema!',
              text: res.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        });
      }
    }
  }
</script>

<style lang="scss">
  #page-login {
    .social-login {
      .bg-facebook {
        background-color: #1551b1;
      }

      .bg-twitter {
        background-color: #00aaff;
      }

      .bg-google {
        background-color: #4285F4;
      }

      .bg-github {
        background-color: #333;
      }
    }
  }
</style>
